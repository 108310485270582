import { MutationDataset, SequenceType } from '../data/MutationDataset';
import styled from 'styled-components';
import { useResizeDetector } from 'react-resize-detector';
import { useMemo, useState } from 'react';
import { sortListByNucMutation } from '../helpers/nuc-mutation';
import { ExternalLink, LinkStyle } from '../components/ExternalLink';

type Props = {
  mutations: MutationDataset;
  sequenceType: SequenceType;
  hideProportions?: boolean;
};

export const MutationList = ({ mutations, hideProportions = false, sequenceType }: Props) => {
  const { width, ref } = useResizeDetector<HTMLDivElement>();
  const displayedMutations = useMemo(() => {
    const filtered = mutations.payload.filter(
      m =>
        !(sequenceType === 'nuc' && m.mutation.startsWith('N')) &&
        !m.mutation.endsWith('-') &&
        m.proportion > 0.05
    );
    return sortListByNucMutation(filtered, x => x.mutation);
  }, [mutations, sequenceType]);
  const [show, setShow] = useState(displayedMutations.length < 100);
  return (
    <div ref={ref}>
      <div>
        <b>Note:</b> These are the difference to the reference genome "MPXV-UK_P2 MT903344.1" generated by{' '}
        <ExternalLink url='https://nextclade.vercel.app' style={LinkStyle.SimpleBlue}>
          Nextclade
        </ExternalLink>
        . The reference genome might change in the future.
      </div>
      There are {displayedMutations.length} differences
      {!hideProportions && ' with a proportion of at least 5%'}.{' '}
      {!show && (
        <button className='underline' onClick={() => setShow(true)}>
          Show the differences.
        </button>
      )}
      {show && (
        <List width={width ?? 1} columnWidth={hideProportions ? 'narrow' : 'normal'}>
          {displayedMutations.map(({ mutation, proportion }) => (
            <MutationEntry key={mutation}>
              {mutation} {!hideProportions && <>({(proportion * 100).toFixed(2)}%)</>}
            </MutationEntry>
          ))}
        </List>
      )}
    </div>
  );
};

interface ListProps {
  width: number;
  columnWidth: 'narrow' | 'normal';
}

const List = styled.ul<ListProps>`
  list-style-type: disc;
  margin-top: 10px;
  column-count: ${props => Math.floor(props.width / (props.columnWidth === 'normal' ? 280 : 150))};
`;

const MutationEntry = styled.li`
  width: 260px;
  display: inline-block;
`;
